<template>
  <div class="bq-detail" >
    <van-nav-bar :title="title" left-text="返回" fixed left-arrow @click-left="goBack"  />
    <div style="padding-top: 44px"></div>
    <van-form @submit="onSubmit" >
      <div class="parts-from">
        <van-field v-model="data.code" name="编码" label="编码:" readonly />
        <van-field v-model="data.name" name="名称" label="名称:" readonly />
        <van-field v-model="data.brand" name="品牌" label="类别:" readonly />
        <van-field v-model="data.specification" name="型号" label="型号:" readonly />
        <van-field v-model="data.unit" name="单位" label="单位:" readonly />
        <van-field v-model="data.number" name="当前库存" label="当前库存:" readonly />
        <van-field v-model="data.current_inventory" name="本月结存" label="本月结存:" readonly />
        <van-field v-model="data.maximum_inventory" name="高储" label="高储:" readonly />
        <van-field v-model="data.minimum_inventory" name="低储" label="低储:" readonly />
        <van-field v-model="data.workshop_id" name="部门" label="部门:" readonly />
        <van-field v-model="data.admin_id" name="负责人" label="负责人:" readonly />
        <van-field v-model="data.price" name="价格" label="价格:" readonly />
        <van-field v-model="data.category_id" name="分类" label="分类:" readonly />
        <van-field v-model="data.provider_id" name="供应商" label="供应商:" readonly />
        <van-field v-model="data.warehouse_id" name="仓库" label="库位:" readonly />
        <van-field v-model="data.status" name="状态" label="状态:" readonly />
        <van-field v-model="data.sort" name="排序" label="排序:" readonly />
      </div>
    </van-form>
  </div>
</template>

<script>
import API from '@/api/stock/parts'
export default {
  inject: ['goUrl', 'isEdit', 'add', 'update'],
  data(){
    return{
      data: {
      },
      editId: null,
      activeName:1,
      title: null,
    }
  },
  created() {
    const edit = this.isEdit()
    if (edit) {
      this.title = '备件详情'
      this.editId = edit - 0
      this.info()
    } else {
      this.title = '新增备件'
    }
  },
  methods:{
    async info() {
      const res = await API.getOne(this.editId)
      this.data = res.data
      console.log(this.data)
    },
    goBack() {
      this.goUrl('/parts')
    },
    onSubmit(values) {
      if (values) {
        if (this.editId) {
          this.update(API, this.data, '更新成功', '/parts')
        } else {
          this.add(API, this.data, '新增成功')
        }
      } else {
        console.log('error submit!!')
        return false
      }
      console.log('submit', values);
    }
  }
}
</script>

<style scoped>

</style>
